import React, { useEffect, useState } from 'react';
import { SamithisList } from './SamithisList';
import { local } from './DataTable';

const Home = (props) => {
  const [todaysData, setData] = useState([]);
  
  function compareDates(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  
    return (
      d1.getDate() === d2.getDate() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getFullYear() === d2.getFullYear()
    );
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(local +'/api/data');
        if (response.ok) {
          const responseData = await response.json();
          const filteredData = responseData.filter((item) => (
            item.samithiName === props.subdomain &&
            compareDates(new Date(), new Date(item.dateField))
          ))
          setData(filteredData);
        } else {
          console.error('Error:', response.status);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };

    fetchData();
  }, [props.subdomain]);

  return (
    <div className='home'>
      <div className='headings'>
             <img src="favicon.ico" width={50} height={50} alt = "emblem logo"/>

           </div>
        <h1>Sri Sathya Sai Seva Organization, {SamithisList[props.subdomain]?.name ?? props.subdomain}</h1>
        <div className='two-sections'>
          <div className='booknow'>
            <h2><a className='link' href='/registration'>Book now</a></h2>
          </div>
          {todaysData.length ? <div className='todaysbooking'>
            <h2>Today's Booking</h2>
            <div className='todaysdetails'>
              {todaysData.map((item) => {return <>
              <div><i>Name </i>: {item.nameField}</div>
              <div><i>Phone </i>: {item.phoneField}</div>
                <div><i>Address</i>: {item.addressField}</div>
                <div><a target='_blank' rel="noreferrer" href={item.locationField}>{item.locationField}</a></div>
                <div><i>More details</i>: {item.moreDetails}</div>
              </>})
              }
            </div>
          </div>:
          "No bookings for today"}
          <div className='check-more'>
            <a className='link' href='/bookings'>Upcoming bhajan bookings</a>
          </div>
        </div>

        {/*<img className="homepageImg" src='swami_photo.jpg' alt = '98 bhajans anouncement'/>*/}
    </div>
  );
};

export default Home;
